import { Fancybox } from '@fancyapps/ui';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

jQuery(document).ready(($) => {
	// CLICK FUNCTIES +++++++++++++++++++++++++++ //

	jQuery(document).ready(($) => {
		Fancybox.bind('[data-fancybox]');

		// CLICK FUNCTIES +++++++++++++++++++++++++++ //
		const $lateral_menu_trigger = $('#cd-menu-trigger');
		const $content_wrapper = $('.cd-main-content');
		const $navigation = $('.mobile-nav-top');

		//open-close lateral menu clicking on the menu icon
		$lateral_menu_trigger.on('click', (event) => {
			event.preventDefault();

			$lateral_menu_trigger.toggleClass('is-clicked');
			$navigation.toggleClass('lateral-menu-is-open');
			$content_wrapper
				.toggleClass('lateral-menu-is-open')
				.one(
					'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
					() => {
						// firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
						$('body').toggleClass('overflow-hidden');
					}
				);
			$('#cd-lateral-nav').toggleClass('lateral-menu-is-open');

			//check if transitions are not supported - i.e. in IE9
			if ($('html').hasClass('no-csstransitions')) {
				$('body').toggleClass('overflow-hidden');
			}
		});

		//close lateral menu clicking outside the menu itself
		$content_wrapper.on('click', (event) => {
			if (!$(event.target).is('#cd-menu-trigger, #cd-menu-trigger span')) {
				$lateral_menu_trigger.removeClass('is-clicked');
				$navigation.removeClass('lateral-menu-is-open');
				$content_wrapper
					.removeClass('lateral-menu-is-open')
					.one(
						'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
						() => {
							$('body').removeClass('overflow-hidden');
						}
					);
				$('#cd-lateral-nav').removeClass('lateral-menu-is-open');
				//check if transitions are not supported
				if ($('html').hasClass('no-csstransitions')) {
					$('body').removeClass('overflow-hidden');
				}
			}
		});

		//open (or close) submenu items in the lateral menu. Close all the other open submenu items.

		$(window).resize(() => {
			if ($(window).width() < 1024) {
				$('.menu-item-has-children')
					.children('a')
					.on('click', function (event) {
						event.preventDefault();
						$(this)
							.toggleClass('submenu-open')
							.next('.sub-menu')
							.slideToggle(200)
							.end()
							.parent('.menu-item-has-children')
							.siblings('.menu-item-has-children')
							.children('a')
							.removeClass('submenu-open')
							.next('.sub-menu')
							.slideUp(200);
					});
			}
		});
	});

	// INIT - Search & Filter pro accordion list
	// create epty list array
	let openFilterList = [];
	// check if list is in local storage
	if (localStorage.getItem('openFilterList') !== null) {
		// set list to list in local storage
		openFilterList = JSON.parse(localStorage.getItem('openFilterList'));
	}
	//FUNCTIONS
	// Add data attr to elements with childlist
	function addTrigger() {
		$('ul.children').each(function () {
			$(this).parent().attr('data-droptrigger', true);
		});
	}

	function openFilters(list) {
		$.each(list, (index, value) => {
			$('.' + value.replace(/ /g, '.'))
				.children('.children')
				.addClass('is-open');
		});
	}
	// CALL FUNCTIONS ON LOAD
	addTrigger();
	openFilters(openFilterList);
	// EVENTLISTENERS
	// Click event to open and close childlist
	$(document).on('click', '[data-droptrigger] > label', function (e) {
		//e.stopPropagation();
		const lastOpenFilter = $(this).parent().attr('class');
		const childList = $(this).parent().children('.children');

		console.log(lastOpenFilter);
		console.log(childList);

		if (!childList.hasClass('is-open')) {
			// open list
			childList.addClass('is-open');
			// add to openFilterList array
			openFilterList.push(lastOpenFilter);
			console.log('open');
		} else {
			// close list
			childList.removeClass('is-open');
			// remove from openFilters array
			openFilterList = $.grep(
				openFilterList,
				(value) => value !== lastOpenFilter
			);

			console.log('close');
		}
		// update localStorage with new openFiltersList
		localStorage.setItem('openFilterList', JSON.stringify(openFilterList));
	});
	// Trigger achter ajax is finished (same as page load)
	$(document).on('sf:ajaxfinish', '.searchandfilter', () => {
		addTrigger();
		if (openFilterList.length !== 0) {
			openFilters(openFilterList);
		}
	});

	// Hamburger toggle -----------
	$('.hamburger').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Algemene toggle -----------
	$('.toggle').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Hamburger toggle -----------
	$('.hamburger').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Algemene toggle -----------
	$('.toggle').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Actieveren popup jobs -----------
	$('.jobtrig').on('click', () => {
		$('.popjobs').addClass('toon');
		$('body').addClass('noscroll');
	});
	$('.inner .sluiten ,body').on('click', () => {
		$('.popjobs').removeClass('toon');
		$('body').removeClass('noscroll');
	});
	$('.jobtrig,.popjobs .inner').click((e) => {
		e.stopPropagation();
	});

	// Actieveren popup -----------
	$('.poptrig').on('click', () => {
		$('.popup').addClass('toon');
		$('body').addClass('noscroll');
	});
	$('.inner .sluiten ,body').on('click', () => {
		$('.popup').removeClass('toon');
		$('body').removeClass('noscroll');
	});
	$('.poptrig,.popup .inner').click((e) => {
		e.stopPropagation();
	});

	// COOKIES ++++++++++++++++++++++++++ //

	// melding-popup
	$('.melding-popup .sluiten').click(() => {
		$('.melding-popup').addClass('hidden');
		Cookies.set('melding-popup.hide', 'true', { expires: 7 });
	});
	if (Cookies.get('melding-popup.hide')) {
		$('.melding-popup').addClass('keep-hidden');
	}

	// melding-boven
	$('.melding-boven .sluiten').click(() => {
		$('.melding-boven').addClass('hidden');
		Cookies.set(
			'melding-boven.hide',
			'true',
			{ element: '.melding-boven' },
			{ expires: 7 }
		);
	});
	if (Cookies.get('melding-boven.hide')) {
		$('.melding-boven').addClass('keep-hidden');
	}

	// SCROLL FUNCTIES ++++++++++++++++++++++++++ //

	// Back to top -----------
	$('.backtotop').click(() => {
		$('html, body').animate({ scrollTop: 0 }, 600);
		return false;
	});

	$(window).scroll(() => {
		if ($(window).scrollTop() > 400) {
			$('.backtotop').addClass('toon');
		} else if ($(window).scrollTop() <= 400) {
			$('.backtotop').removeClass('toon');
		}
	});

	$(window).scroll(() => {
		if ($(window).scrollTop() + $(window).height() === $(document).height()) {
			$('.backtotop').addClass('fix');
		} else {
			$('.backtotop').removeClass('fix');
		}
	});

	// VASTE VENDORS +++++++++++++++++++++++++++ //

	// Toewijzen justified gallery -----------
	if ($('.acf-justigal')[0]) {
		$('.acf-justigal').justifiedGallery();
	}

	// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
	//-----------------------------------------------------------------------------------------------------------//

	/*
	On Scroll - Menu Classes
	 */
	const body = $('body');
	const header = $('#primary-nav-desktop');

	$(window).scroll(() => {
		const scroll = $(window).scrollTop();
		if (scroll >= 50) {
			body.addClass('scrolling-active');
			header.addClass('sticky-nav-desktop');
		} else {
			body.removeClass('scrolling-active');
			header.removeClass('sticky-nav-desktop');
		}
	});

	/*
	Smooth Scrolling
	 */

	// Select all links with hashes
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, '') ==
					this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				let target = $(this.hash);
				target = target.length
					? target
					: $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate(
						{
							scrollTop: target.offset().top - 96
						},
						1000,
						() => {
							// Callback after animation
							// Must change focus!
							const $target = $(target);
							$target.focus();
							if ($target.is(':focus')) {
								// Checking if the target was focused
								return false;
							}
							$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						}
					);
				}
			}
		});

	/*
	Begin Swipers
	 */
	const swiper = new Swiper('.swiper-news', {
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			nextEl: '.swiper-news-next',
			prevEl: '.swiper-news-prev'
		},
		breakpoints: {
			767: {
				slidesPerView: 3
			},
			1025: {
				slidesPerView: 5
			}
		}
	});

	/*
	Filters Sidebar Mobile
	 */
	$('.product-archive-filters#sidebar .side-trigger').on('click', () => {
		$('.product-archive-filters#sidebar').toggleClass('active');
	});
});
